import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import Layout from "../../components/layout"
import {
    PricesListCard, PricesListCardHeader,
    PricesListCardRow
} from "../../components/pricesListCard"
import SEO from "../../components/seo"
import { Bold, Row, Section, Subtitle, Title } from "../../components/styled"
import { colors } from "../../constants/colors"
import { mq } from "../../constants/media-queries"
import { pricesOne, pricesTwo } from "../../content/prices"

const navItems = [
  {
    title: "Danse",
    path: "/danse",
  },
  {
    title: "Horaires",
    path: "/danse/horaires",
  },
  {
    title: "Infos",
    path: "/infos",
  },
]

const PricesIntro = styled.div`
  margin-bottom: 60px;
  p {
    line-height: 1.5;
    margin-bottom: 16px;
  }
`

const PricesListCardContainer = styled.div`
  display: grid;
  ${mq.sm`
    grid-template-columns: repeat(2, 1fr);
  `}
  gap: 40px;
`

const PricesListCardBox = styled.div``

const CutPriceInfo = styled.p`
  padding: 20px;
  color: ${colors.pink};
  line-height: 1.5;
  margin-bottom: 40px;
`

const ContactLink = styled(Link)`
  color: ${colors.pink};
`

const PricesPage = ({ data }) => {
  return (
    <Layout navItems={navItems}>
      <SEO title="Tarifs" />
      <Section hero>
        <Row>
          <Subtitle>Danse</Subtitle>
          <Title>Tarifs</Title>
          <PricesIntro>
            <p>
              <Bold>
                Assurance obligatoire : 10€ par élève (à ajouter aux tarifs
                ci-dessous)
              </Bold>
            </p>
            <p>
              5% de réduction pour le 2ème membre d’une même famille
              <br />
              <Bold>
                [non-cumulable avec la réduction offerte lors des journées de
                réinscriptions prioritaires !]
              </Bold>
            </p>
            <p>Abonnement annuel, possibilité de payer en 2 fois ou demander un plan d'étalement</p>
            <p>
              Engagement pour l’année : l’abonnement est non-remboursable (sauf
              en cas de maladie, d’accident ou de blessure couvert par un
              certificat médical de plus d’un mois envoyé par l’élève à notre
              asbl, les 4 premières semaines ne seront en aucun cas remboursées)
            </p>
            <p>
              Toute inscription nécessite un acompte de 40€ par cours - Aucun
              acompte ne sera remboursé
            </p>
          </PricesIntro>
          <PricesListCardContainer>
            <PricesListCardBox className="p1">
              <PricesListCard item={pricesTwo} />
            </PricesListCardBox>
            <PricesListCardBox className="p2">
              <PricesListCard item={pricesOne} />
              <CutPriceInfo>
                * Le prix entre parenthèses est applicable pour les membres de
                la saison 2023-2024 si paiement en 1x lors de la semaine des
                réinscriptions prioritaires
              </CutPriceInfo>
              <PricesListCardHeader>
                Plus de 4h30 par semaine
              </PricesListCardHeader>
              <PricesListCardRow>
                <ContactLink to="/infos">Contactez-nous</ContactLink>
              </PricesListCardRow>
            </PricesListCardBox>
          </PricesListCardContainer>
        </Row>
      </Section>
    </Layout>
  )
}

export default PricesPage
