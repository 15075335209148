import React, { Fragment } from "react"
import styled from "styled-components"

import { colors } from "../constants/colors"

export const PricesListCardHeader = styled.div`
  background: linear-gradient(90deg, ${colors.pink} 0%, ${colors.purple} 100%);
  display: flex;
  align-items: center;
  color: ${colors.white};
  min-height: 50px;
  padding: 8px 18px;
  font-size: 18px;
  line-height: 1.4;
`
export const PricesListCardRow = styled.div`
  background: ${colors.white};
  padding: 12px 18px;
  display: grid;
  grid-template-columns: 60% 20% 20%;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.greyE};
  }
  p:not(:first-of-type) {
    justify-self: end;
  }
  p:last-child {
    color: ${colors.pink};
  }
`
export const PricesListCard = ({ item }) => {
  return (
    <Fragment>
      <PricesListCardHeader>
        {item.quantity === 1
          ? "1 cours par semaine"
          : "Plusieurs cours par semaine"}
      </PricesListCardHeader>
      {item.prices?.map(priceRow => (
        <PricesListCardRow
          key={priceRow.price + priceRow.cutPrice + priceRow.hours}
        >
          <p>{priceRow.hours}</p>
          <p>{priceRow.price}€</p>
          <p>({priceRow.cutPrice}€)</p>
        </PricesListCardRow>
      ))}
    </Fragment>
  )
}
